<template>
  <b-img
    :src="imgUrl"
    fluid
    center
    alt="Vuina"
    class="logo-vuina"
  />
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BImg,
  },
  data() {
    return {
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line global-require
        return require('@/assets/images/logo/logo-vuina.png')
      }
      // eslint-disable-next-line global-require
      return require('@/assets/images/logo/logo-vuina.png')
    },
  },
}
</script>
<style scoped>
.logo-vuina {
  width: 50%;
}
</style>
