<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <logo />

        <b-card-title class="mb-1 mt-2">
          Bienvenido a Vuina! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Por favor ingresar sus credenciales
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="usuario"
              label="Usuario"
            >
              <validation-provider
                #default="{ errors }"
                name="usuario"
                rules="required"
              >
                <b-form-input
                  id="usuario"
                  v-model="form.usuario"
                  name="usuario"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Ingresar usuario"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label-for="password"
              label="Contraseña"
            >
              <validation-provider
                #default="{ errors }"
                name="contraseña"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="form.status"
                name="checkbox"
                value="accepted"
                unchecked-value="not_accepted"
                class="custom-control-secondary"
              >
                Recordar credenciales
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click="login"
            >
              Ingresar
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import Logo from '@/views/components/Logo.vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line import/no-cycle
import usuarioService from '@/service/usuarioService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        usuario: '',
        password: '',
        status: 'accepted',
      },
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.$store.commit('app/UPDATE_ISLOADING', false)
  },
  async created() {
    this.form = {
      usuario: localStorage.getItem('usuario'),
      password: localStorage.getItem('password'),
      status: localStorage.getItem('status'),
    }
  },
  methods: {
    async login() {
      const success = await this.$refs.loginForm.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Validando credenciales.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          const parametro = {
            usuario: this.form.usuario,
            password: this.form.password,
            origen: 'WEBCONFIG',
          }
          const responseLogin = await usuarioService.postUsuarioLogin(parametro)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Confirmación',
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Datos de acceso conforme.',
              },
            },
            {
              position: 'bottom-center',
            },
          )
          localStorage.setItem('token', responseLogin.data.data.accessToken)
          localStorage.setItem('status', this.form.status)
          if (this.form.status === 'accepted') {
            localStorage.setItem('usuario', this.form.usuario)
            localStorage.setItem('password', this.form.password)
          } else {
            localStorage.removeItem('usuario')
            localStorage.removeItem('password')
          }
          const responseUsuario = await usuarioService.getUsuarioData(responseLogin.data.data.accessToken)
          localStorage.setItem('userData', JSON.stringify(responseUsuario.data.data))
          localStorage.setItem('userAbilities', JSON.stringify(responseUsuario.data.permisos))
          this.$ability.update(responseUsuario.data.permisos)

          if (responseUsuario.data.permisos.length === 0) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Error usuario no tiene acceso al sistema.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
            this.$store.commit('app/UPDATE_ISLOADING', false)
          } else {
            setTimeout(() => {
              this.$router.push({ name: responseUsuario.data.permisos[0].action })
            }, 1000)
          }
        } catch (error) {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
