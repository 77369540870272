import axios from '@axios'
import store from '@/store'

export default {
  async postUrlData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/me/openVuinaUrl`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postQrData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/me/get-mfaqrUrl`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postReenviarQrData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/me/resend-mfaqrUrl`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postTipoUsuarioData(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/tienda/getTipoUsuario`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postUsuarioClave(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/me/resetPassword`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postUsuarioLogin(data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/auth/login`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        username: data.usuario,
        password: data.password,
        origen: data.origen,
        fcmid: '',
      },
    })
    return response
  },
  async getUsuarioData(token) {
    const response = await axios({
      method: 'get',
      url: `${store.state.app.api}/me`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postUsuarioData(token, tipo, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getUsuarios`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        tipo,
        idValue: id,
      },
    })
    return response
  },
  async postUsuarioInsert(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertUsuarios`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        username: data.numero,
        nickname: data.nickname,
        dateBirthday: data.cumpleanos,
        campaignId: data.campana,
        supervisorUsername: data.supervisor,
        perfilName: data.perfil,
        nombre: data.nombre,
        apellidoPaterno: data.apellidoPaterno,
        apellidoMaterno: data.apellidoMaterno,
        numeroDocumento: data.numero,
        tipoDocumento: data.tipo,
        email: data.email,
        estado: data.estado,
        roles: data.rol,
      },
    })
    return response
  },
  async postUsuarioUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/updateUsuarios`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        username: data.numero,
        nickname: data.nickname,
        dateBirthday: data.cumpleanos,
        campaignId: data.campana,
        supervisorUsername: data.supervisor,
        perfilName: data.perfil,
        nombre: data.nombre,
        apellidoPaterno: data.apellidoPaterno,
        apellidoMaterno: data.apellidoMaterno,
        numeroDocumento: data.numero,
        tipoDocumento: data.tipo,
        email: data.email,
        estado: data.estado,
        idValue: data.id,
        roles: data.rol,
      },
    })
    return response
  },
  async postUsuarioDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteUsuarios`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
  async postArchivoData(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/process/users`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  },
}
